import React, { useContext } from "react";
import OoPaper from "../element/page-element/OoPaper";
import UserData from "./UserData";
import DeleteUserData from "./DeleteUserData";
import theme from "../element/theme";
import SEO from "../element/SEO";
import { AuthContext } from "../authentication/AuthContextProvider";

const seo = {
  title: "Settings - Oberion",
  description: "Your profile settings.",
};

const defaultPaper = {
  padding: "2rem",
  margin: "1.5rem 0",
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.contrastText,
  elevation: 1,
};

const Account = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      <SEO {...seo} />

      <UserData data={authContext.getUserObject()} expansionPanelStyles={defaultPaper} />

      <OoPaper {...defaultPaper}>
        <DeleteUserData />
      </OoPaper>
    </>
  );
};

export default Account;