import React, { useContext } from "react";
import * as PropTypes from "prop-types";
import { navigate } from "gatsby";
import { AuthContext } from "../authentication/AuthContextProvider";
import { ROUTES } from "./InternalRoute";

/**
 * Permit only logged in users in protected / private routes.
 * Navigating to a protected route redirects to the login page.
 * @see https://www.gatsbyjs.org/tutorial/authentication-tutorial/
 */
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const authContext = useContext(AuthContext);

  if (!authContext.isLoggedIn() && (location.pathname !== ROUTES.STEAM_LOGIN.TO)) {
    // console.log(`location.pathname: ${location.pathname}`);
    // console.log(location);

    if (authContext.isAccountDeleted()) {
      // Special case for deleting the account while on the settings route
      // Makes sure logout confirmation + account deletion confirmation is displayed
      navigate(ROUTES.STEAM_LOGIN.LOGOUT,
        { state: { loggedOut: true, accountDeleted: true } });


    } else if (location.pathname === ROUTES.SETTINGS_ACCOUNT.TO) {
      // TODO also needs to check all sub routes: "/settings*"
      // Special case for logging out while on the settings route
      // Makes sure logout confirmation is displayed
      navigate(ROUTES.STEAM_LOGIN.LOGOUT,
        { state: { loggedOut: true, accountDeleted: false } });


    } else {
      // All other cases and routes
      navigate(ROUTES.STEAM_LOGIN.TO);
    }
    return null;
  }

  return <Component {...rest} />;
};
PrivateRoute.defaultProps = {
  location: undefined,
};
PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
};

export default PrivateRoute;
