import React from "react";
// noinspection NpmUsedModulesInstalled
import { Router } from "@reach/router";
import Layout from "../components/element/layout/Layout";
import LayoutSetBackground from "../components/element/layout/LayoutSetBackground";
import theme, { ooContentWidth, grey8 } from "../components/element/theme";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import { ROUTES } from "../components/navigation/InternalRoute";
import Account from "../components/settings/Account";
import PageBanner from "../components/element/page-element/PageBanner";
import PrivateRoute from "../components/navigation/PrivateRoute";


const Settings = () => (
  <Layout hideNewsletter>

    <LayoutSetBackground alignItems="center" justifyContent="center"
                         backgroundColor={grey8} color={theme.palette.secondary.contrastText}>

      <PageBanner heading="Settings and Privacy" minHeight="0" />

      <LayoutColumn alignItems="flex-start"
                    justifyContent="center"
                    textAlign="left"
                    maxWidth={ooContentWidth}
                    paddingBottom="4rem"
                    paddingTop="0">

        <Router>
          <PrivateRoute path={ROUTES.SETTINGS_ACCOUNT.TO} component={Account} />
        </Router>

      </LayoutColumn>
    </LayoutSetBackground>
  </Layout>
);

export default Settings;
