import React, { useContext } from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import OoSpacing from "../element/page-element/OoSpacing";
import { AuthContext } from "../authentication/AuthContextProvider";
import { postDeleteUser } from "../authentication/steam/steam-auth";

const useStyles = makeStyles(theme => ({
  checkboxRoot: {
    color: theme.palette.primary.main,
  },
  formHelperTextRoot: {
    color: theme.palette.secondary.contrastText,
  },
  disabled: {
    backgroundColor: `${theme.palette.grey["300"]} !important`,
    color: `${theme.palette.grey["500"]} !important`,
  },
}));

const heading = "Delete my Account";
const checkboxLabel = "Delete my account and all of its data";
const formHelperText = [
  "Click the checkbox and the button to delete all your personal data from Oberion.",
  "Your account and your data on Steam remain unchanged. You can log in via Steam at any time to recreate your account.",
  "You'll be automatically logged out after clicking delete.",
];


export default function DeleteUserData() {
  const authContext = useContext(AuthContext);
  const classes = useStyles();
  const [state, setState] = React.useState({
    deleteData: false,
  });

  const handleChange = name => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await postDeleteUser(authContext.getSteamid());
    // console.log("postDeleteUser: Response:");
    // console.log(response);

    if (response.data.status === "success") {
      // console.log("response.data.status: " + response.data.status);
      // TODO handle delete errors properly, show error message
    }

    /* --- Private Route component initiates navigation to log out screen --- */
    authContext.setAccountIsDeleted();
    authContext.logUserOut();
  };

  return (
    <>
      <Typography variant="h5">{heading}</Typography>

      <form onSubmit={handleSubmit}>
        <FormControlLabel
          label={checkboxLabel}
          control={(
            <Checkbox
              checked={state.deleteData}
              onChange={handleChange("deleteData")}
              value="deleteData"
              color="primary"
              className={classes.checkboxRoot} />
          )}
        />
        <FormHelperText className={classes.formHelperTextRoot}>{formHelperText[0]}</FormHelperText>
        <FormHelperText className={classes.formHelperTextRoot}>{formHelperText[1]}</FormHelperText>
        <FormHelperText className={classes.formHelperTextRoot}>{formHelperText[2]}</FormHelperText>
        <OoSpacing marginTop={1} />

        <Button variant="contained"
                color="primary"
                type="submit"
                disabled={!state.deleteData}
                classes={{ disabled: classes.disabled }}>
          Delete
        </Button>
      </form>
    </>
  );
}
