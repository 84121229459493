import React from "react";
import * as PropTypes from "prop-types";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import JSONPretty from "react-json-pretty";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import customLightTheme from "./jsonPrettyLightTheme";
import { linkViolet } from "../element/theme";

// noinspection JSCheckFunctionSignatures
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: props => props.padding,
    margin: props => props.margin,
    backgroundColor: props => props.backgroundColor,
    color: props => props.color,
  },
  summary: {
    padding: 0,
    borderBottom: `solid 1px ${theme.palette.grey["400"]}`,
    position: "relative",
  },
  caption: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    color: linkViolet,
  },
  details: {
    padding: 0,
    display: "flex",
    width: "100%",
  },
  icon: {
    color: linkViolet,
  },
}));

export default function UserData({ data, expansionPanelStyles }) {
  const classes = useStyles({ ...expansionPanelStyles });
  const [expanded, setExpanded] = React.useState(false);
  const [captionText, setCaption] = React.useState("show more");

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setCaption(isExpanded ? "show less" : "show more");
  };

  // noinspection JSIncompatibleTypesComparison
  return (
    <ExpansionPanel expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    className={classes.root}>
      <ExpansionPanelSummary className={classes.summary}
                             expandIcon={<ExpandMoreIcon className={classes.icon} />}>
        <Typography variant="h5">Your Account Data</Typography>
        <Typography variant="caption" component="span" className={classes.caption}>
          {captionText}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <JSONPretty id="json-pretty" theme={customLightTheme} data={data} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

UserData.propTypes = {
  data: PropTypes.object.isRequired,
  expansionPanelStyles: PropTypes.object.isRequired,
};
